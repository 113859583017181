import "./styles/Routes.css";
import { Alert, Box, CircularProgress } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { useDcpAuth } from "../../../contexts/DcpAuthContext";
import { PosterResponse } from "./interfaces";
import { useDcpApi, availablePaths } from "../../../services/DcpService";
// import PosterList from "./components/PosterList";
import PosterCard from "./components/PosterCard";
import FilterComponent from "./components/FilterComponent";
import SearchFilterView from "./components/SearchFilterView";


export const PosterPoster = () => {
  const { uid } = useParams(); // send with request to authenticate user
  const { accessToken, initialLoad, authIsLoading, authError, isAuthenticated, authenticate } = useDcpAuth();
  const { dcpApi, dcpResponse, dcpIsLoading, dcpError } = useDcpApi();

  const [posters, setPosters] = useState<PosterResponse[]>([]);
  const [mutablePosters, setMutablePosters] = useState<PosterResponse[]>([]);
  const [searchString, setSearchString] = useState<string>("");
  const [date, setDate] = useState<{ startDate: string; endDate: string }>({
    startDate: "",
    endDate: "",
  });
  const [currentPosters, setCurrentPosters] = useState<PosterResponse[]>([]);
  const [itemOffset, setItemOffset] = useState<number>(0);
  const [pageCount, setPageCount] = useState<number>(0);
  const itemsPerPage = 6;
  const [layout, setLayout] = useState<string>("card");

  useEffect(() => {
    if (initialLoad && uid) {
      authenticate(uid, "");
    }
  }, [initialLoad]);

  useEffect(() => {
    const fetchPosters = async () => {
      await dcpApi(availablePaths.posterApi.posterList(), "GET");
    }

    if (isAuthenticated) {
      fetchPosters();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (dcpResponse) {
      if (!dcpResponse.error && dcpResponse.data.list) {
        setPosters(dcpResponse.data.list);
        setMutablePosters(dcpResponse.data.list);
      } else {
        console.log("Failed to fetch posters");
      }
    }
  }, [dcpResponse])

  useEffect(() => {
    const filteredArray = posters.filter((obj) =>
      obj.title.toLowerCase().includes(searchString.toLowerCase())
    );

    setMutablePosters(filteredArray);
  }, [searchString]);

  useEffect(() => {
    if (date.startDate.length > 0 && date.endDate.length > 0) {
      let filteredArray: PosterResponse[] = [];
      if (date.startDate === date.endDate) {
        posters.map((post) => {
          if (
            new Date(post.metadata.modified).toLocaleDateString() ===
            date.startDate
          ) {
            filteredArray.push(post);
          }
        });
      } else {
        posters.map((post) => {
          if (
            new Date(post.metadata.modified) >= new Date(date.startDate) &&
            new Date(post.metadata.modified) <= new Date(date.endDate)
          ) {
            filteredArray.push(post);
          }
        });
      }

      setMutablePosters(filteredArray);
    }
  }, [date]);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    const currentItems = mutablePosters.slice(itemOffset, endOffset);
    const tempPageCount = Math.ceil(mutablePosters.length / itemsPerPage);
    setCurrentPosters(currentItems);
    setPageCount(tempPageCount);
  }, [mutablePosters, itemOffset]);

  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % mutablePosters.length;
    setItemOffset(newOffset);
  };

  return (
    <Box sx={{ maxWidth: "1050px", margin: "0 auto" }}>
      {authIsLoading ? (
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <CircularProgress color="info" />
        </Box>
      ) : authError ? (
        <Alert severity='error'>{authError}</Alert>
      ) : !isAuthenticated ? (
        <Alert severity='error'>Missing permission</Alert>
      ) : (
        <div className="w-full">
          <div className="w-full flex flex-row items-start justify-between">
            <FilterComponent
              setDate={setDate}
              isTemplate={false}
              isDownloadPage={false}
            />
            <SearchFilterView
              setSerachString={setSearchString}
              setLayout={setLayout}
            // date={date}
            // setDate={setDate}
            />
          </div>
          {dcpIsLoading ? (
            <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
              <CircularProgress color="info" />
            </Box>
          ) : dcpError ? (
            <Alert severity='error'>{dcpError}</Alert>
          ) : dcpResponse ? (
            <div className={layout === "list" ? "grid grid-cols-1 mb-5 gap-4" : "grid-container"}>
              {currentPosters.map((poster) =>
                <PosterCard
                  key={poster.id}
                  poster={poster}
                  currentPoster={mutablePosters}
                  setCurrentPoster={setMutablePosters}
                  layout={layout}
                />
              )}
            </div>
          ) : null}
          <div className=" w-full flex flex-row items-center justify-center mx-auto">
            <ReactPaginate
              breakLabel="..."
              nextLabel=" >"
              onPageChange={handlePageClick}
              pageRangeDisplayed={4}
              marginPagesDisplayed={2}
              pageCount={pageCount}
              previousLabel="< "
              renderOnZeroPageCount={null}
              breakClassName="bg-gray-200 rounded-full mx-1 py-1 pb-2 px-2"
              breakLinkClassName="text-gray-600"
              containerClassName="flex"
              pageClassName="mx-2"
              pageLinkClassName="text-black font-semibold border border-black rounded-full px-3 py-2 "
              activeLinkClassName="text-black bg-[#F97316]"
              previousClassName="mx-1"
              previousLinkClassName="text-black font-semibold bg-white border border-black rounded-full px-3 py-2 "
              nextClassName="mx-1"
              nextLinkClassName="text-black font-semibold bg-white border border-black rounded-full px-3 py-2 "
            />
          </div>
        </div>
      )}
    </Box>
  )
}