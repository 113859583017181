import React, { useEffect, useState } from "react";
import {
  OutlinedInput,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  SelectChangeEvent,
  Checkbox,
  ListItemText,
} from "@mui/material";
import { useTranslation } from "react-i18next";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const MultiSelect = ({
  options,
  selectedOptions,
  isClosed,
  onChange,
}: {
  options: { name: string; id: number }[];
  selectedOptions: number[];
  isClosed: boolean;
  onChange?: (changedData: { new: number[]; delete: number[] }) => void;
}) => {
  const [selectionArray, setSelectionArray] = useState<number[]>([]);

  const { t } = useTranslation("MultiSelectPage");

  const handleChange = (event: SelectChangeEvent<typeof selectionArray>) => {
    const {
      target: { value },
    } = event;

    const arr =
      typeof value === "string"
        ? value.split(",").map((item) => parseInt(item))
        : value;
    setSelectionArray(arr);

    const deletedList = selectedOptions.filter((id) => !arr.includes(id));
    const newList = arr.filter((id) => !selectedOptions.includes(id));
    onChange({ new: newList, delete: deletedList });
  };

  useEffect(() => {
    let temp: number[] = [];
    if (selectedOptions.length > 0) {
      selectedOptions.map((id) => {
        const op = options.find((option) => option.id === id);
        // if (op && op.name) {
        //   temp.push(op.name);
        // }
        temp.push(op.id);
      });
    }
    setSelectionArray(temp);
  }, [selectedOptions]);

  // useEffect(() => {
  //   // if (selectionArray.length > 0) {
  //   //   let temp: number[] = [];
  //   //   selectionArray.map((name) => {
  //   //     const tId = options.find((option) => option.name === name)?.id!;
  //   //     if (tId) {
  //   //       const findIdx = selectedOptions.findIndex((id) => id === tId);
  //   //       if (findIdx === -1) {
  //   //         temp.push(tId);
  //   //       }
  //   //     }
  //   //   });
  //   //   setNewAssignedRole!(temp);
  //   // }

  //   const deletedList = selectedOptions.filter(
  //     (id) => !selectionArray.includes(id)
  //   );
  //   const newList = selectionArray.filter(
  //     (id) => !selectedOptions.includes(id)
  //   );
  //   onChange({ new: newList, delete: deletedList });
  // }, [selectionArray]);
  useEffect(() => {
    if (isClosed) {
      setSelectionArray([]);
    }
  }, [isClosed]);

  return (
    <div>
      <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel>{t("Role")}</InputLabel>
        <Select
          multiple
          value={selectionArray}
          onChange={handleChange}
          input={<OutlinedInput label={t("Role")} />}
          MenuProps={MenuProps}
          renderValue={(selected) =>
            options
              .filter((item) => selected.includes(item.id))
              .map((option) => option.name)
              .join(", ")
          }
        >
          {options.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              <Checkbox checked={selectionArray.indexOf(option.id) > -1} />
              <ListItemText primary={option.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
