import { Navigate, Route, Routes } from 'react-router-dom';
import * as page from '../pages';
import * as admin from '../pages/admin';
import TopBarLayout from '../components/layouts/TopBarLayout';
import FullLayout from '../components/layouts/FullLayout';
import { authStore } from '../App';

export const AuthenticatedRoutes = () => {
  return (
    <>
      <TopBarLayout>
        <Routes>
          <Route path="/" element={<page.Home />} />
          <Route path="/home" element={<page.Home />} />

          <Route path="/organisasjon" element={<page.Organisasjon />} />
          <Route path="/medlem" element={<page.Medlem />} />
          <Route path="/bedrift" element={<page.Bedrift />} />
          <Route path="/tillitsverv" element={<page.Tillitsverv />} />
          <Route path="/stipend" element={<page.Stipend />} />
          <Route path="/tariff" element={<page.Tariff />} />
          <Route path="/Økonomi" element={<page.Økonomi />} />
          <Route path="/kommunikasjon" element={<page.Kommunikasjon />} />

          <Route path="/profile" element={<page.Profile />} />
          <Route path="/Settings" element={<page.Settings />} />

          {/** Admin routes */}
          <Route path="/admin/*" element={<AdminRoutes />} />

          {/** @todo: on prod, replace these with <Route path="/*" element={<Navigate to='/' replace />} /> */}
          <Route path="/:path" element={<page.NotFound />} />
          <Route path="/*" element={<page.NotFound />} />
        </Routes>
      </TopBarLayout>
    </>
  );
};

const AdminRoutes = () => {
  const { userRoles } = authStore();
  return (
    <Routes>
      {userRoles.includes("admin") ? (
        <>
          <Route path="/table" element={<admin.TableOverview />} />
          <Route path="/*" element={<Navigate to='table' replace />} />
        </>
      ) : (
        <Route path="/*" element={<page.MissingPermission />} />
      )}
    </Routes>
  );
};