import permissions from "../db/permissions.json";
interface Permissions {
  [resource: string]: {
    [action: string]: string[];
  };
}

const permissionsObj: Permissions = permissions;

const checkPermission = (
  roles: string[],
  resource: string,
  action: string
): boolean => {
  const allowedRoles = permissionsObj[resource]?.[action];
  if (!allowedRoles) return false;

  //   if (allowedRoles === roles) return true;
  //   else return false;

  // Check if any role in `roles` array is allowed
  const allowed = roles.some((role) => allowedRoles.includes(role));

  return allowed;
};

export default checkPermission;