import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  Select,
} from "@mui/material";
import { TextField, Button, MenuItem } from "@mui/material";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import ViewListIcon from "@mui/icons-material/ViewList";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate, useLocation } from "react-router-dom";
import "../styles/SearchFilterView.css";
import { TemplateResponse } from "../interfaces";
import { useTranslation } from "react-i18next";
import checkPermission from "./permissions";
import { useDcpAuth } from "../../../../contexts/DcpAuthContext";
import { useDcpApi, availablePaths } from "../../../../services/DcpService";

const SearchFilterView = ({
  setSerachString,
  setLayout,
}: {
  setSerachString?: Function;
  setLayout: Function;
  date?: string;
  setDate?: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [type, setType] = useState<string>("newsletter");
  const [title, setTitle] = useState<string>("");
  const navigate = useNavigate();
  const location = useLocation();

  // Fix this, bad to use url
  let path = location.pathname.split("/");
  let loca = "poster";
  if (path.length > 1) {
    loca = path[2];
  }
  // const isDownloadPage = location.pathname === "download";
  const isNewsletterPage = loca === "newsletter";
  const isPosterPage = loca === "poster";
  const isTemplatePage = loca === "template";
  const isDownload = loca === "download";
  const [templateList, setTemplateList] = useState<TemplateResponse[]>([]);
  const [selectedTemplateId, setSelectedTemplateId] = useState<number | string>(
    "Blank"
  );
  const { dcpApi, dcpResponse, dcpIsLoading, dcpError } = useDcpApi();
  const { role, isAuthenticated } = useDcpAuth();

  const { t } = useTranslation("SearchFilterViewPage");

  useEffect(() => {
    const fetchTemplates = async () => {
      await dcpApi(availablePaths.templateApi.getTemplateList(), "GET");
    };

    if (isAuthenticated) {
      fetchTemplates();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (dcpResponse) {
      if (!dcpResponse.error && dcpResponse.data.list) {
        let templateListTemp: TemplateResponse[] = [];
        const contentType = isNewsletterPage ? "Newsletter" : "Poster";
        dcpResponse.data.list.map((obj: any) => {
          if (obj.type.text === contentType) templateListTemp.push(obj);
        });
        setTemplateList(templateListTemp);
      } else {
        console.log("Failed to fetch newsletters");
      }
    }
  }, [dcpResponse]);

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isTemplatePage) {
      navigate(
        `/poster/create-template/${
          isNewsletterPage ? "newsletter" : "poster"
        }/${title}/`
      );
    } else {
      let templateIDStr = "/0";
      if (selectedTemplateId !== "Blank") {
        templateIDStr = "/" + selectedTemplateId;
      }
      navigate(
        `/poster/create/${
          isNewsletterPage ? "newsletter" : "poster"
        }/${title}${templateIDStr}`
      );
    }
  };

  // Update the handleDialogClose to reset form state
  const handleDialogClose = () => {
    setOpenDialog(false);
    setType("");
    setTitle("");
  };

  // Layouts handler

  const handleCardView = () => {
    setLayout("card");
  };

  const handleListView = () => {
    setLayout("list");
  };

  return (
    <div className="content-header flex-1">
      <TextField
        label={t("Search")}
        variant="outlined"
        size="small"
        className="search-bar"
        onChange={(e) => setSerachString!(e.target.value)}
      />

      {/* {!(isNewsletterPage || isPosterPage) &&
      !role.some((r) => permission.template.add_new.includes(r)) ? (
        <></>
      ) : (
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleDialogOpen}
          sx={{
            backgroundColor: "#f97316", // Approximate Tailwind's orange-500
            color: "white",
            "&:hover": {
              backgroundColor: "#ea580c", // Approximate Tailwind's orange-600
            },
          }}
        >
          {t("Add New")}
        </Button>
      )} */}

      {isNewsletterPage && checkPermission(role, "newsletter", "add_new") && (
        // role.some((r) => permission.newsletter.add_new.includes(r)) && (
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleDialogOpen}
          sx={{
            backgroundColor: "#f97316", // Approximate Tailwind's orange-500
            color: "white",
            "&:hover": {
              backgroundColor: "#ea580c", // Approximate Tailwind's orange-600
            },
          }}
        >
          {t("Add New")}
        </Button>
      )}

      {isPosterPage && checkPermission(role, "poster", "add_new") && (
        // role.some((r) => permission.poster.add_new.includes(r)) && (
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleDialogOpen}
          sx={{
            backgroundColor: "#f97316", // Approximate Tailwind's orange-500
            color: "white",
            "&:hover": {
              backgroundColor: "#ea580c", // Approximate Tailwind's orange-600
            },
          }}
        >
          {t("Add New")}
        </Button>
      )}

      {!(isNewsletterPage || isPosterPage || isDownload) &&
        checkPermission(role, "template", "add_new") && (
          // role.some((r) => permission.template.add_new.includes(r)) && (
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleDialogOpen}
            sx={{
              backgroundColor: "#f97316", // Approximate Tailwind's orange-500
              color: "white",
              "&:hover": {
                backgroundColor: "#ea580c", // Approximate Tailwind's orange-600
              },
            }}
          >
            {t("Add New")}
          </Button>
        )}

      <div>
        {/* View Switch Buttons */}
        <IconButton className="" onClick={handleCardView}>
          <ViewModuleIcon />
        </IconButton>
        <IconButton onClick={handleListView}>
          <ViewListIcon />
        </IconButton>

        <Dialog
          sx={{
            "& .MuiDialog-container": {
              alignItems: "flex-start",
            },
          }}
          open={openDialog}
          onClose={handleDialogClose}
        >
          <DialogTitle>
            {isNewsletterPage && t("Add New Newsletter")}
            {isPosterPage && t("Add New Poster")}
            {isTemplatePage && t("Add New Template")}
          </DialogTitle>
          <DialogContent>
            <form onSubmit={handleSubmit}>
              <div className="space-y-4">
                <TextField
                  autoFocus
                  required
                  margin="dense"
                  id="name"
                  label={t("Name")}
                  type="text"
                  fullWidth
                  variant="outlined"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
                {isTemplatePage ? (
                  <FormControl fullWidth margin="normal">
                    <InputLabel>Type</InputLabel>
                    <Select
                      required
                      value={type as string}
                      label="Type"
                      onChange={(e) => setType(e.target.value)}
                    >
                      <MenuItem value={"newsletter"}>Newsletter</MenuItem>
                      <MenuItem value={"poster"}>Poster</MenuItem>
                    </Select>
                  </FormControl>
                ) : (
                  <FormControl fullWidth margin="normal">
                    <InputLabel>{t("Template")}</InputLabel>
                    <Select
                      value={selectedTemplateId as string}
                      label="Type"
                      onChange={(e) => setSelectedTemplateId(e.target.value)}
                    >
                      <MenuItem value={"Blank"}>{t("Blank")}</MenuItem>
                      {templateList.map((templateObj) => {
                        return (
                          <MenuItem key={templateObj.id} value={templateObj.id}>
                            {templateObj.title}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                )}
              </div>
              <DialogActions>
                <Button onClick={handleDialogClose} color="inherit">
                  {t("Cancel")}
                </Button>
                <Button type="submit" color="primary">
                  {t("Next")}
                </Button>
              </DialogActions>
            </form>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
};

export default SearchFilterView;
