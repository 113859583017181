import { BlockListRender, DME } from "dmeditor";
import { LayoutFixedPageEntity } from "./entity";
import { LayoutContainer } from "./styled";
import { NewsLetterHeader } from "../newsletter-header/NewsletterHeader";
import { nanoid } from "nanoid";

export const LayoutFixedPage = (
  props: DME.WidgetRenderProps<LayoutFixedPageEntity>
) => {
  return (
    <LayoutContainer
      layoutHeight={props.blockNode.data.settings.height}
      className={props.rootClasses}
    >
      {/* <NewsLetterHeader  rootClasses={""} styleClasses={{}} active={false} mode={"edit"} path={[]} blockNode={{id: nanoid(),type: "parat-newsletter-header",style: { _: "default" },data: {},}}> */}
        
      {/* </NewsLetterHeader> */}
      <div className="dme-w-list">
        <BlockListRender
          blockData={props.blockNode.children || []}
          path={props.path}
          mode={props.mode}
        />
      </div>
      <div className="dme-w-footer">
        <img src={props.blockNode.data.footer} />
      </div>
    </LayoutContainer>
  );
};
