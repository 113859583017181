import { registerWidget, registerWidgetStyleOption } from "dmeditor";
import definition from "./definition";
import { NewsLetterHeader } from "./NewsletterHeader";

export default () => {
  registerWidgetStyleOption("parat-newsletter-header", [
    {
      name: "Default",
      identifier: "default",
      cssStyle: `
        & .dme-block-container img{
          min-width: 60px;
        }
      `,
    },
  ]);
  registerWidget(definition, { render: NewsLetterHeader });
};
