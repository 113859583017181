import styled from "@emotion/styled";

export const LayoutContainer = styled.div<{ layoutHeight: number }>`
  height: calc(var(--dme-container-total-width)* 1.41414);
  overflow-y: auto;
  
  & > .dme-w-list {
    height: ${(props) => props.layoutHeight + "px" || "auto"};
    overflow-y: hidden;
    padding-bottom: 10px;
  }
`;
