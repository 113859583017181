import { Box, useTheme, Button, ButtonGroup, TextField, MenuItem, CircularProgress, Alert, FormControl, InputLabel, Select } from '@mui/material';
import React from 'react';
import { Area, Bar, BarChart, CartesianGrid, Cell, ComposedChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { DataByYear, DisplayData, TableHolderProps, UserDataItem, SaleryStatistic } from '../Interface';
import { useApi } from '../../../../services/HttpService';
import { useParams } from 'react-router-dom';

//consider calculating tick steps for bar with https://stackoverflow.com/questions/64625509/is-there-a-way-to-have-evenly-sized-ticks-for-recharts-axis-while-keeping-my-max

// Function to create a salary statistic object
function createSaleryStatistic(
  name: string,
  stat_year: number,
  salary_agreed_avg: number,
  salary_agreed_med: number,
  salary_avg: number,
  salary_med: number,
  bonus_avg: number,
  bonus_med: number,
  addition_avg: number,
  addition_med: number,
  non_member: boolean = false,
) {
  let s: SaleryStatistic = { name, stat_year, salary_agreed_avg, salary_agreed_med, salary_avg, salary_med, bonus_avg, bonus_med, addition_avg, addition_med, non_member }
  return s;
}

export const TableHolder: React.FC<TableHolderProps> = ({
  actAsMemberNumber,
  monthlySalary,
  bonus,
  additional,
  overtime,
  gl2,
  gl3,
  group,
  educationLevel,
  yearsSinceEducation,
}) => {

  const theme = useTheme();   // Material-UI theme
  const { uid } = useParams();
  const { api, response, isLoading, error } = useApi();  // Custom hook for API calls. HttpService.tsx
  const [contact, setContact] = React.useState<any>({});
  const [showStandardTable, setShowStandardTable] = React.useState(true);   // Show standard table by default
  const [showDetailedTable, setShowDetailedTable] = React.useState(false);  // Hide detailed table by default
  const [showLineChart, setShowLineChart] = React.useState(false);   // Hide line chart by default
  const [selectedAvgMedianButton, setSelectedAvgMedianButton] = React.useState("avg");
  const [rows, setRows] = React.useState<SaleryStatistic[]>([]); // All data from filters (members and non-members)
  const [displayData, setDisplayData] = React.useState<DisplayData[]>([]);  // All data from filters (members and non-members), for use in line/area chart.
  const [latestDataDetailed, setLatestDataDetailed] = React.useState<SaleryStatistic[]>([]);  // Data for the latest year with both member and non-member data
  const [combinedDetailedData, setCombinedDetailedData] = React.useState<(SaleryStatistic | UserDataItem)[]>([]);   // Combined data from database and user input for stacked bar chart ("Detaljert")

  const calculateUserSalaryTotal = () => {
    const salaryTotal = (Number(monthlySalary) ?? 0) + (Number(bonus) ?? 0) + (Number(additional) ?? 0) + (Number(overtime) ?? 0);
    return salaryTotal;
  }

  // User data input from the form
  const userDataDetailed: UserDataItem[] = [{
    name: contact.name ? contact.name : "Egen lønn",
    salary_agreed_avg: Number(monthlySalary),
    salary_agreed_med: Number(monthlySalary),
    bonus_avg: Number(bonus),
    bonus_med: Number(bonus),
    addition_avg: Number(additional),
    addition_med: Number(additional),
    overtime: Number(overtime),
    salary_avg: calculateUserSalaryTotal(),
    salary_med: calculateUserSalaryTotal(),
    stat_year: new Date().getFullYear() - 1, // Current year minus one, because the data is from the previous year
  }];

  React.useEffect(() => {
    const getSalaryStatistic = async () => {
      ////////// TEST //////////
      // let data = { "uid": uid, "gl2": 4, "gl3": 4, "gl4": 12, "gl5": 60, "gl6": 480 }
      ////////// TEST //////////

      let data = { "uid": uid, "act_as_member_number": actAsMemberNumber, "gl2": gl2, "gl3": gl3, "gl4": group, "gl5": educationLevel, "gl6": yearsSinceEducation }
      await api("/getSalaryStatistic", "POST", data);
    }

    getSalaryStatistic();
  }, [gl2, gl3, group, educationLevel, yearsSinceEducation]);

  React.useEffect(() => {
    var stats: SaleryStatistic[] = [];
    var num = 1;

    var memberData = response?.data?.memberData;
    var nonMemberData = response?.data?.nonMemberData;
    var contact = response?.contact;

    if (contact) {
      setContact(contact);
    }
    if (memberData) {
      memberData.forEach((e: any) => {
        stats.push(createSaleryStatistic("Parat", e.stat_year, e.salary_agreed_avg, e.salary_agreed_med, e.salary_avg, e.salary_med, e.bonus_avg, e.bonus_med, e.addition_avg, e.addition_med))
        num += 1;
      });
    }
    if (nonMemberData) {
      nonMemberData.forEach((e: any) => {
        stats.push(createSaleryStatistic("SSB", e.stat_year, e.salary_agreed_avg, e.salary_agreed_med, e.salary_avg, e.salary_med, e.bonus_avg, e.bonus_med, e.addition_avg, e.addition_med, true))
        num += 1;
      });
    }

    stats.sort((a, b) => a.stat_year > b.stat_year ? 1 : -1);

    // Find the latest year using the 'stat_year' property
    const latestYear = Math.max(...stats.map(s => s.stat_year));

    // Group data by year
    let displayData: DisplayData[] = [];
    stats.forEach(s => {
      let entry = displayData.find(i => i.stat_year === s.stat_year);
      if (!entry) {
        entry = {
          stat_year: s.stat_year,
        };
        displayData.push(entry);
      }

      // Add or update fields for Parat and SSB
      const entryKeyPrefix = s.non_member ? "ssb_" : "";
      entry[entryKeyPrefix + "salary_agreed_avg"] = s.salary_agreed_avg;
      entry[entryKeyPrefix + "salary_agreed_med"] = s.salary_agreed_med;
      entry[entryKeyPrefix + "salary_avg"] = s.salary_avg;
      entry[entryKeyPrefix + "salary_med"] = s.salary_med;
      entry[entryKeyPrefix + "bonus_avg"] = s.bonus_avg;
      entry[entryKeyPrefix + "bonus_med"] = s.bonus_med;
      entry[entryKeyPrefix + "addition_avg"] = s.addition_avg;
      entry[entryKeyPrefix + "addition_med"] = s.addition_med;
    });

    // Add user data for the latest year
    const userData = userDataDetailed[0];
    const userIndex = displayData.findIndex(i => i.stat_year === latestYear);
    if (userIndex > -1) {
      // Add or update user-specific data fields
      displayData[userIndex] = {
        ...displayData[userIndex],
        user_salary_agreed_avg: userData.salary_agreed_avg,
        user_salary_avg: userData.salary_avg,
        user_bonus_avg: userData.bonus_avg,
        user_addition_avg: userData.addition_avg,
        user_overtime: userData.overtime,
      };
    } else {
      displayData.push({
        stat_year: latestYear,
        user_salary_agreed_avg: userData.salary_agreed_avg,
        user_salary_avg: userData.salary_avg,
        user_bonus_avg: userData.bonus_avg,
        user_addition_avg: userData.addition_avg,
        user_overtime: userData.overtime,
      });
    }

    setDisplayData(displayData);
    setRows(stats);
  }, [response]);

  // Find the latest year with both member and non-member data
  React.useEffect(() => {
    const findAndSetLatestData = () => {
      if (rows.length === 0) return;

      // Group data by year
      const dataByYear: DataByYear = rows.reduce((acc, item) => {
        if (!acc[item.stat_year]) {
          acc[item.stat_year] = { members: [], nonMembers: [] };
        }
        if (item.non_member) {
          acc[item.stat_year].nonMembers.push(item);
        } else {
          acc[item.stat_year].members.push(item);
        }
        return acc;
      }, {} as DataByYear);

      // Find the latest year with both member and non-member data
      const latestYearWithBoth = Object.keys(dataByYear).reduce((latest, year) => {
        if (dataByYear[parseInt(year)].members.length > 0 && dataByYear[parseInt(year)].nonMembers.length > 0) {
          return Math.max(latest, parseInt(year));
        }
        return latest;
      }, 0);

      // Filter the rows to only include data from this latest year with both member and non-member data
      if (latestYearWithBoth !== 0) {
        const latestDataBoth = rows.filter(item => item.stat_year === latestYearWithBoth);

        // Optional: sort or manipulate latestData if necessary
        // For example, reversing the order
        const latestDataReversed = [...latestDataBoth].reverse();
        setLatestDataDetailed(latestDataReversed);
      }
    };

    findAndSetLatestData();
  }, [rows]);


  // Merge the latest data with the user data
  React.useEffect(() => {
    const updatedCombinedDetailedData = [...latestDataDetailed, ...userDataDetailed];
    setCombinedDetailedData(updatedCombinedDetailedData);
    // console.log('combinedDetailedData:', updatedCombinedDetailedData);
  }, [latestDataDetailed]);


  // Select between average and median salary
  const averageMedianSelector = [
    {
      value: "avg",
      label: "Gjennomsnittslønn"
    },
    {
      value: "median",
      label: "Medianlønn"
    }
  ]

  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: 7 }}>
        <h1>Resultat</h1>
      </Box>

      {/* Dropdown for selecting between average and median salary. */}
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: 1 }}>
        <FormControl variant="standard" sx={{ width: { xs: "70%", sm: "40%" } }}>
          <InputLabel id="avg-med-dropdown">{selectedAvgMedianButton ? "" : averageMedianSelector[0].label}</InputLabel>
          <Select
            id="avg-med-dropdown"
            label={selectedAvgMedianButton ? "" : averageMedianSelector[0].label}
            aria-label="Velg mellom gjennomsnittslønn og medianlønn"
            value={selectedAvgMedianButton}
            onChange={(e) => setSelectedAvgMedianButton(e.target.value)}
            variant="standard"
            fullWidth
            sx={{ mr: 1 }}
            MenuProps={{
              autoFocus: false,
              disableAutoFocusItem: true,
              disableEnforceFocus: true,
              disableAutoFocus: true
            }}
          >
            {averageMedianSelector.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
          </Select>
        </FormControl>
      </Box>

      {isLoading ? (
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <CircularProgress color="info" />
        </Box>
      ) : error ? (
        <Alert severity='error'>{error}</Alert>
      ) : response ? (
        <>
          {/* First graph: "Standard". Total salary for Parat, SSB and User */}
          {showStandardTable ? (
            <Box role="img" aria-label="Søylediagram som viser total lønn for Parat, SSB og innlogget medlem">
              <ResponsiveContainer width="100%" height={300}>
                <BarChart
                  width={500}
                  height={300}
                  data={combinedDetailedData}
                  margin={{ top: 5, right: 0, left: 0, bottom: 5 }}
                  aria-label="Søylediagram av total lønn for Parat, SSB og innlogget medlem"
                >
                  <XAxis dataKey="name" aria-label="Navn" />
                  <YAxis type="number" domain={[
                    0,
                    (dataMax: number) => Math.ceil((dataMax + 1) / 10000) * 10000,
                  ]} tickCount={6} aria-label="Lønn i kroner" />
                  <CartesianGrid vertical={false} aria-hidden="true" />
                  <Tooltip aria-label="Lønn informasjon" contentStyle={{ backgroundColor: `${theme.palette.mode === 'dark' ? '#1C2126' : '#fff'}` }} />

                  {/* Display average or median salary */}
                  {selectedAvgMedianButton === "avg" ? (
                    <Bar dataKey="salary_avg" name="Snitt lønn" barSize={180} aria-label="Gjennomsnittslønn" tabIndex={0} fill={theme.palette.mode === 'dark' ? '#fff' : '#000'}>
                      <Cell key={1} fill={theme.palette.primary.main} />
                      <Cell key={2} fill="#aaa" />
                      <Cell key={3} fill="#475867" />
                    </Bar>
                  ) : (
                    <Bar dataKey="salary_med" name="Median lønn" barSize={180} aria-label="Medianlønn" tabIndex={0}>
                      <Cell key={1} fill={theme.palette.primary.main} />
                      <Cell key={2} fill="#aaa" />
                      <Cell key={3} fill="#475867" />
                    </Bar>
                  )}

                </BarChart>
              </ResponsiveContainer>
            </Box>
          ) : null}

          {/* Second graph: "Detaljert". Stacked bar chart for "Avtalt månedslønn", "Bonus", "Tillegg" and "Overtid" */}
          {showDetailedTable ? (
            <Box role="img" aria-label="Søylediagram for avtalt månedslønn, bonus, tillegg og overtid">
              <ResponsiveContainer width="100%" height={300}>
                <BarChart
                  width={500}
                  height={300}
                  data={combinedDetailedData}
                  margin={{ top: 5, right: 0, left: 0, bottom: 5 }}
                  aria-label="Søylediagram for avtalt månedslønn, bonus, tillegg og overtid"
                >
                  <XAxis dataKey="name" aria-label="Navn" />
                  <YAxis dataKey={"salary_avg"} type="number" domain={[
                    0,
                    (dataMax: number) => Math.ceil((dataMax + 1) / 10000) * 10000,
                  ]} tickCount={6} aria-label="Lønn i kroner" />
                  <CartesianGrid vertical={false} />
                  <Tooltip contentStyle={{ backgroundColor: `${theme.palette.mode === 'dark' ? '#1C2126' : '#fff'}` }} />
                  <Legend aria-label="Diagramforklaring" />

                  {selectedAvgMedianButton === "avg" ? (
                    <>
                      <Bar dataKey="salary_agreed_avg" name="Avtalt månedslønn" stackId={"a"} barSize={180} fill={theme.palette.primary.main} aria-label="Avtalt månedslønn" tabIndex={0} />
                      <Bar dataKey="bonus_avg" name="Bonus" stackId={"a"} barSize={180} fill="#F2C057" aria-label="Bonus" tabIndex={0} />
                      <Bar dataKey="addition_avg" name="Tillegg" stackId={"a"} barSize={180} fill="#A3A2A2" aria-label="Tillegg" tabIndex={0} />
                      <Bar dataKey="overtime" name="Overtid" stackId={"a"} barSize={180} fill="#31708e" aria-label="Overtid" tabIndex={0} />
                    </>
                  ) : (
                    <>
                      <Bar dataKey="salary_agreed_med" name="Avtalt månedslønn" stackId={"a"} barSize={180} fill={theme.palette.primary.main} aria-label="Avtalt månedslønn" tabIndex={0} />
                      <Bar dataKey="bonus_med" name="Bonus" stackId={"a"} barSize={180} fill="#F2C057" aria-label="Bonus" tabIndex={0} />
                      <Bar dataKey="addition_med" name="Tillegg" stackId={"a"} barSize={180} fill="#A3A2A2" aria-label="Tillegg" tabIndex={0} />
                      <Bar dataKey="overtime" name="Overtid" stackId={"a"} barSize={180} fill="#31708e" aria-label="Overtid" tabIndex={0} />
                    </>
                  )}

                </BarChart>
              </ResponsiveContainer>
            </Box>
          ) : null}

          {/* Third graph: "Linje". Area chart for average salary for Parat, SSB, and a Bar for User */}
          {showLineChart ? (
            <Box role="img" aria-label="Linjediagram som viser gjennomsnittslønn eller medianlønn for Parat, SSB og innlogget medlem">
              <ResponsiveContainer width="100%" height={300}>
                <ComposedChart
                  width={500}
                  height={300}
                  data={displayData}
                  margin={{ top: 5, right: 30, left: 0, bottom: 5 }}
                  aria-label="Linjediagram som viser lønn over tid"
                >
                  <defs>
                    <linearGradient id="colorParat" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="15%" stopColor={theme.palette.primary.main} stopOpacity={1} />
                      <stop offset="100%" stopColor={theme.palette.primary.main} stopOpacity={0} />
                    </linearGradient>
                    <linearGradient id="colorSSB" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="15%" stopColor="#aaa" stopOpacity={1} />
                      <stop offset="100%" stopColor="#aaa" stopOpacity={0} />
                    </linearGradient>
                  </defs>
                  <XAxis dataKey="stat_year" scale={"point"} aria-label="Navn" />
                  <YAxis
                    type="number"
                    aria-label="Lønn i kroner"
                    tickCount={6}
                    domain={[
                      (dataMin: number) => {
                        if (dataMin === userDataDetailed[0].salary_avg) {
                          if (dataMin > 10000) {
                            return Math.floor((dataMin - 10000) / 10000) * 10000;
                          }
                          return 0;
                        }
                        if (dataMin > 1000) {
                          return Math.floor((dataMin - 1000) / 10000) * 10000;
                        }
                        return 0;
                      },
                      (dataMax: number) => Math.ceil((dataMax + 1000) / 5000) * 5000,
                    ]} />
                  <CartesianGrid vertical={false} />
                  <Tooltip contentStyle={{ backgroundColor: `${theme.palette.mode === 'dark' ? '#1C2126' : '#fff'}` }} />
                  <Legend aria-label="Diagramforklaring" />

                  {/* Select between average and median salary. User data stays the same.  */}
                  <Bar dataKey="" barSize={20} fill="rgba(0, 0, 0, 0)" tabIndex={0} legendType="none" />
                  <Bar dataKey="user_salary_avg" name={contact.name ? contact.name : "Egen lønn"} barSize={25} fill="rgba(49, 112, 142, 0.7)" activeBar={{ stroke: 'rgba(49, 112, 142, 1)', strokeWidth: 3 }} aria-label="Lønn innlogget bruker" tabIndex={0} />
                  {selectedAvgMedianButton === "avg" ? (
                    <>
                      <Area type="linear" dataKey="salary_avg" name="Parat" dot={true} stroke={theme.palette.primary.main} strokeWidth={2} activeDot={{ r: 6 }} fill="url(#colorParat)" aria-label="Gjennomsnittslønn Parat" tabIndex={0} />
                      <Area type="linear" dataKey="ssb_salary_avg" name="SSB" dot={true} stroke="#aaa" strokeWidth={2} activeDot={{ r: 6 }} fill="url(#colorSSB)" aria-label="Gjennomsnittslønn SSB" tabIndex={0} />
                    </>
                  ) : (
                    <>
                      <Area type="linear" dataKey="salary_med" name="Parat" dot={true} stroke={theme.palette.primary.main} strokeWidth={2} activeDot={{ r: 6 }} fill="url(#colorParat)" aria-label="Medianlønn Parat" tabIndex={0} />
                      <Area type="linear" dataKey="ssb_salary_med" name="SSB" dot={true} stroke="#aaa" strokeWidth={2} activeDot={{ r: 6 }} fill="url(#colorSSB)" aria-label="Medianlønn SSB" tabIndex={0} />
                    </>
                  )}

                </ComposedChart>
              </ResponsiveContainer>
            </Box>
          ) : null}
        </>
      ) : null}

      {/* Button group for switching between the three graphs */}
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: 13, marginLeft: '5%', marginRight: "5%" }}>
        <ButtonGroup fullWidth variant="outlined">
          <Button
            aria-label="Velg standard søylediagram"
            onClick={() => { setShowStandardTable(true); setShowDetailedTable(false); setShowLineChart(false); }}
            sx={{
              backgroundColor: showStandardTable ? theme.palette.primary.main : theme.palette.background.default,
              color: showStandardTable ? "white" : theme.palette.primary.main,
            }}
          >
            Standard
          </Button>

          <Button
            aria-label="Velg detaljert søylediagram"
            onClick={() => { setShowStandardTable(false); setShowDetailedTable(true); setShowLineChart(false); }}
            sx={{
              backgroundColor: showDetailedTable ? theme.palette.primary.main : theme.palette.background.default,
              color: showDetailedTable ? "white" : theme.palette.primary.main,
            }}
          >
            Detaljert
          </Button>

          <Button
            aria-label="Velg linjediagram"
            onClick={() => { setShowStandardTable(false); setShowDetailedTable(false); setShowLineChart(true); }}
            sx={{
              backgroundColor: showLineChart ? theme.palette.primary.main : theme.palette.background.default,
              color: showLineChart ? "white" : theme.palette.primary.main,
            }}
          >
            Linje
          </Button>
        </ButtonGroup>
      </Box>
    </Box>
  );
}