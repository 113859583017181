import React from 'react';
import Header from './navigations/Header';

const TopBarLayout = ({ children }: any) => {
  return (
    <>
      <Header />
      <main>{children}</main>
    </>
  );
}

export default TopBarLayout;