// import React, { createContext, useState, useContext } from "react";

// interface AuthContextType {
//   accessToken: string | null;
//   refreshToken: string | null;
//   setTokens: (accessToken: string, refreshToken: string) => void;
// }

// const DcpTokenContext = createContext<AuthContextType>({
//   accessToken: null,
//   refreshToken: null,
//   setTokens: () => {},
// });

// export const DcpTokenProvider: React.FC<{ children: React.ReactNode }> = ({
//   children,
// }) => {
//   const [accessToken, setAccessToken] = useState<string | null>(null);
//   const [refreshToken, setRefreshToken] = useState<string | null>(null);

//   const setTokens = (accessToken: string, refreshToken: string) => {
//     setAccessToken(accessToken);
//     setRefreshToken(refreshToken);
//   };

//   return (
//     <DcpTokenContext.Provider value={{ accessToken, refreshToken, setTokens }}>
//       {children}
//     </DcpTokenContext.Provider>
//   );
// };

// export const useDcpToken = () => useContext(DcpTokenContext);



// Potential refresh
import React, { createContext, useState, useContext, useEffect } from "react";
import { useDcpApi, availablePaths, GetNewAccessToken } from "../services/DcpService";
import { useApi } from "../services/HttpService";
import localStorageService, { LocalStorageKeys } from '../services/LocalStorageService';
import { Toaster } from "react-hot-toast";
import { DMInit, MultiUpload } from "digimaker-ui";
// import { initLanguage } from "dmeditor";
// import { registerDefaultWidgets } from "dmeditor";

import { dmeditorInit } from "../pages/public/poster/dmeditor/DMEditorInit";
dmeditorInit();

interface AuthContextType {
  accessToken: string | null;
  refreshToken: string | null;

  fullName: string;
  userName: string;
  email: string;
  role: string[];
  imageUrl: string;
  language: string;

  initialLoad: boolean;
  authIsLoading: boolean;
  authError: string;
  isAuthenticated: boolean;

  setTokens: (accessToken: string | null, refreshToken: string | null) => void;
  authenticate: (uid: string, actAs: string) => void;
  fetchUserInfo: () => void;
}

const DcpAuthContext = createContext<AuthContextType>({
  accessToken: null,
  refreshToken: null,

  email: "",
  fullName: "",
  imageUrl: "",
  language: "",
  role: [""],
  userName: "",

  initialLoad: false,
  authIsLoading: true,
  authError: "",
  isAuthenticated: false,

  setTokens: () => { },
  authenticate: () => { },
  fetchUserInfo: () => { },
});

export const DcpAuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { dcpApi, dcpResponse, dcpIsLoading, dcpError } = useDcpApi();
  const { api, response, isLoading, error } = useApi();
  // initLanguage("nor-NO");
  // registerDefaultWidgets();

  // token
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [refreshToken, setRefreshToken] = useState<string | null>(null);

  // user
  const [email, setEmail] = useState<string>("");
  const [fullName, setFullName] = useState<string>("");
  const [imageUrl, setImageUrl] = useState<string>("");
  const [language, setLanguage] = useState<string>("");
  const [role, setRole] = useState<string[]>([]);
  const [userName, setUserName] = useState<string>("");

  // auth states
  const [initialLoad, setInitialLoad] = useState<boolean>(false);
  const [authIsLoading, setAuthIsLoading] = useState<boolean>(true);
  const [authError, setAuthError] = useState<string>("");
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  useEffect(() => {
    // Initialize tokens from localStorage
    const tokenData = localStorageService.getItem(LocalStorageKeys.DCP_TOKENS);
    if (tokenData) {
      setAccessToken(tokenData.accessToken);
      setRefreshToken(tokenData.refreshToken);
    }

    setInitialLoad(true);
  }, []);

  const setTokens = (newAccessToken: string | null, newRefreshToken: string | null) => {
    setAccessToken(newAccessToken);
    setRefreshToken(newRefreshToken);
    localStorageService.setItem(LocalStorageKeys.DCP_TOKENS, { accessToken: newAccessToken, refreshToken: newRefreshToken });
  };

  // try to get user info if accessToken, else get access token through digimember via uid
  const authenticate = async (uid: string, actAs: string) => {
    setAuthError('');
    setAuthIsLoading(true);
    if (accessToken) {
      fetchUserInfo();
    } else {
      await api("/getDcpUser", "POST", { "uid": uid, "act_as_member_number": actAs });
    }
  }

  // if refresh_token is recived from digimember, get access_token and then dcp user
  useEffect(() => {
    if (response) {
      GetNewAccessToken(response.refresh_token).then(accessToken => {
        if (accessToken) {
          setTokens(accessToken, response.refresh_token);
          fetchUserInfo();
        } else {
          setAuthError("failed to get access token");
          setAuthIsLoading(false);
        }
      }).catch(_ => {
        localStorageService.removeItem(LocalStorageKeys.DCP_TOKENS);
        setAuthError("failed to authenticate");
        setAuthIsLoading(false);
      })
    }
  }, [response]);

  useEffect(() => {
    if (error) {
      localStorageService.removeItem(LocalStorageKeys.DCP_TOKENS);
      setAuthError(error);
      setAuthIsLoading(false);
    }
  }, [error]);

  const fetchUserInfo = async () => {
    setAuthError('');
    await dcpApi(availablePaths.users.getCurrentAdminInfo(), "GET");
    setAuthIsLoading(false);
  }

  useEffect(() => {
    if (dcpResponse) {
      setFullName(dcpResponse.data.firstname + " " + dcpResponse.data.lastname);
      setEmail(dcpResponse.data.email);
      setRole(dcpResponse.data.roles);
      setImageUrl(dcpResponse.data.picture);
      setLanguage(dcpResponse.data.language);
      setUserName(dcpResponse.data.login);
      setIsAuthenticated(true);
    }
  }, [dcpResponse])

  useEffect(() => {
    if (dcpError) {
      setAuthError(dcpError);
    }
  }, [dcpError]);

  return (
    <>
      <DcpAuthContext.Provider value={{
        accessToken,
        refreshToken,
        email,
        fullName,
        imageUrl,
        language,
        role,
        userName,
        initialLoad,
        authIsLoading,
        authError,
        isAuthenticated,
        setTokens,
        authenticate,
        fetchUserInfo
      }}>
        <Toaster
          position="top-right"
          reverseOrder={false}
          containerStyle={{
            top: 70,
          }}
        />

        {/* init dm only when refreshToken is available, else render page and authenticate which gets a new one */}
        {isAuthenticated && (
          <DMInit
            lang="nor-NO"
            getRefreshToken={() => {
              const tokens = localStorageService.getItem(LocalStorageKeys.DCP_TOKENS);

              // const userInfo = localStorage.getItem("userInfo");
              if (tokens) {
                // const infoObj = JSON.parse(userInfo);
                return tokens.refreshToken;
              } else {
                return "";
              }
            }}
            browseAfterList={(props: any) => (
              <div style={{ marginTop: "15px" }}>
                <label>
                  <b>Upload image</b>
                </label>
                <MultiUpload
                  name="multiUpload"
                  service="content"
                  multi={true}
                  format="image/*"
                  value={[]}
                  afterAction={props.refresh}
                  parent={props.parent}
                />
              </div>
            )}
            viewSettings={(content: string) => {
              return {
                inline_fields: ["image"],
                block_fields: ["name", "image"],
                browselist: {
                  viewmode: "block",
                  columns: ["name"],
                  blockFields: ["image", "name"],
                  sort_default: [["id", "desc"]],
                  row_actions: [],
                  pagination: 25,
                },
              };
            }}
            dateTime={{ format: "DD.MM.YYYY HH:mm" }}
          >
            {children}
          </DMInit>
        )}
        {!isAuthenticated && (
          <>
            {children}
          </>
        )}
      </DcpAuthContext.Provider>
    </>
  );
};

export const useDcpAuth = (): AuthContextType => {
  const context = useContext(DcpAuthContext);
  if (context === undefined) {
    throw new Error("useDcpAuth must be used within an DcpAuthProvider");
  }
  return context;
}