import React from 'react';
import BaseTheme from '../themes/BaseTheme'

/**
 * Global layout that is always applied
 * Applied in App.tsx
 */

interface Props { children: JSX.Element | JSX.Element[] }
const BaseLayout = ({ children }: Props) => {
  return (
    <BaseTheme>
      {children}
    </BaseTheme>
  )
}

export default BaseLayout;