import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Alert, Box, Checkbox, CircularProgress, FormControlLabel, Tooltip } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

interface hardDeleteProps {
  open: boolean,
  title?: string,
  bodyText?: string,
  includeHardDelete?: boolean,
  loading?: boolean;
  errorMessage?: string;
  onClose?: () => void,
  onConfirm?: (hardDelete: boolean) => void,
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const HardDeleteDialog = (props: hardDeleteProps) => {
  const [hardDelete, setHardDelete] = React.useState(false);
  const [error, setError] = React.useState("");

  React.useEffect(() => {
    setHardDelete(false);
    setError("")
  }, [props.open]);

  React.useEffect(() => {
    setError(props.errorMessage)
  }, [props.errorMessage]);

  const handleClose = () => {
    if (props.loading) {
      return;
    }
    props.onClose();
  }

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="dialog-title"
      open={props.open}
    >
      <DialogTitle sx={{ m: 0, p: 2 }} id="dialog-title">
        {props.title ? props.title : "Delete confirmation"}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={(theme) => ({
          position: 'absolute',
          right: 8,
          top: 8,
          color: theme.palette.grey[500],
        })}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        {error && (
          <Box sx={{ width: "100%", mb: 2 }}>
            <Alert severity="error">{error}</Alert>
          </Box>
        )}
        <Typography gutterBottom>
          {props.bodyText ? props.bodyText : "Are you sure you want to delete?"}
        </Typography>
        {props.includeHardDelete && (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <FormControlLabel
              label={"hard delete"}
              sx={{ mr: 0 }}
              control={
                <Checkbox
                  checked={hardDelete}
                  onChange={(_, checked) => {
                    setHardDelete(checked);
                  }}
                />
              }
            />
            <Tooltip title={"By checking this box, the data will be permanently deleted."}>
              <InfoOutlinedIcon sx={
                {
                  ml: 1,
                  color: "#0093ff",
                  fontSize: 28,
                }}
              />
            </Tooltip>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          color="darkgrey"
          type="submit"
          variant="contained"
          size="medium"
          disabled={props.loading}
          onClick={handleClose}
        >
          Cancle
        </Button>
        <Button
          color="error"
          type="submit"
          variant="contained"
          disabled={props.loading}
          onClick={() => props.onConfirm(hardDelete)}
          endIcon={props.loading && <CircularProgress size={22} />}
        >
          Delete
        </Button>
      </DialogActions>
    </BootstrapDialog >
  )
}

export default HardDeleteDialog