import React, { useState } from "react";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import { Box, Button, DialogContent, Slider, Typography } from "@mui/material";
import Cropper from "react-easy-crop";

interface AspectRatio {
  value: number;
  text: string;
}

interface ImageCropDialogProps {
  id: string;
  imageUrl: string;
  cropInit?: { x: number; y: number };
  zoomInit?: number;
  aspectInit?: AspectRatio;
  setCroppedAreaPixels: (croppedAreaPixels: {
    width: number;
    height: number;
    x: number;
    y: number;
  }) => void;
  setRotate;
  rotate: number;
}

const aspectRatios: AspectRatio[] = [
  { value: 1 / 1, text: "1:1" },
  { value: 4 / 3, text: "4/3" },
  { value: 16 / 9, text: "16/9" },
  { value: 1 / 2, text: "1/2" },
];

const ImageCropDialog: React.FC<ImageCropDialogProps> = ({
  id,
  imageUrl,
  cropInit = { x: 0, y: 0 },
  zoomInit = 1,
  aspectInit,
  setCroppedAreaPixels,
  setRotate,
  rotate,
}) => {
  const availableRatios = aspectInit
    ? [aspectInit, ...aspectRatios]
    : aspectRatios;
  const [zoom, setZoom] = useState<number>(zoomInit);
  const [crop, setCrop] = useState<{ x: number; y: number }>(cropInit);
  const [aspect, setAspect] = useState(availableRatios[0]);

  const onCropChange = (crop: { x: number; y: number }) => {
    setCrop(crop);
  };

  const onZoomChange = (zoom: number) => {
    setZoom(zoom);
  };
  const onRotateClick = () => {
    setRotate((prevRotate) => (prevRotate + 90) % 360);
  };

  const onCropComplete = (croppedArea: any, croppedAreaPixels: any) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  return (
    <div>
      <DialogContent
        dividers
        sx={{
          background: "#333",
          position: "relative",
          height: 400,
          width: "auto",
          minWidth: { sm: 500 },
        }}
      >
        <Cropper
          image={imageUrl}
          zoom={zoom}
          crop={crop}
          rotation={rotate}
          aspect={aspect.value}
          onCropChange={onCropChange}
          onZoomChange={onZoomChange}
          onCropComplete={onCropComplete}
        />
      </DialogContent>
      <Box sx={{ flexDirection: "column", mx: 2, my: 1, mt: 1 }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography sx={{ pr: 3 }}>Zoom</Typography>
          <Slider
            size="medium"
            aria-label="Zoom"
            value={zoom}
            onChange={(e, value) => {
              onZoomChange(value as number);
            }}
            step={0.1}
            min={1}
            max={3}
          />

          <RotateLeftIcon
            sx={{
              ml: 1,
              width: 40,
              cursor: "pointer",
              color: "primary.main",
              pl: 2,
            }}
            onClick={onRotateClick}
          />
        </Box>
        <Box sx={{ display: "flex", mt: 1 }}>
          <Box sx={{ mr: 2 }}>Ratio:</Box>
          <Box>
            {availableRatios.map((item) => (
              <Button
                size="small"
                variant={item.value == aspect.value ? "outlined" : "text"}
                onClick={() => setAspect(item)}
              >
                {item.text}
              </Button>
            ))}
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default ImageCropDialog;
