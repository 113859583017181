import React from 'react';
import { useNavigate, useParams } from "react-router-dom";

export const NotFound = () => {
  const navigate = useNavigate();
  const { path } = useParams();

  return (
    <>
      <div className='App-header'>
        <div>
          <h3>404: Page not found</h3>
          {(path != null) ?
            <p>{path}</p> :
            null
          }
          <a
            onClick={() => navigate(-1)}
            style={{ cursor: "pointer", fontSize: "1rem" }}
          >
            Gå tilbake
          </a>
        </div>
      </div>
    </>
  )
}