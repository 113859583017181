import { DME, DMEData } from "dmeditor";
import { nanoid } from "nanoid";
import { NewsLetterHeaderEntity } from "./entity";
const definition: DME.Widget = {
  category: "basic",
  icon: "parat-logo",
  name: "Newsletter header",
  type: "parat-newsletter-header",
  events: {
    createBlock: (): DMEData.Block<NewsLetterHeaderEntity> => {
      return {
        id: nanoid(),
        type: "parat-newsletter-header",
        style: { _: "default" },
        data: {},
        children: [
          {
            id: nanoid(),
            type: "image",
            data: {
              src: "https://images.unsplash.com/photo-1561336313-0bd5e0b27ec8?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
              description: "",
              settings: {},
            },
          },
          {
            id: nanoid(),
            type: "image",
            data: {
              src: "https://www.parat.com/view-resources/dachser2/public/parat/parat_logo.svg",
              description: "",
              settings: {},
            },
          },
        ],
      };
    },
    updateData: () => {},
  },
  settings: [],
};

export default definition;
