import { BlockListRender, DME, dmeConfig } from "dmeditor";
import { LayoutPosterEntity } from "./entity";
import { LayoutContainer } from "./styled";

const dcpUrl = process.env.REACT_APP_DCP_URL;

export const LayoutPoster = (
  props: DME.WidgetRenderProps<LayoutPosterEntity>
) => {
  return (
    <LayoutContainer className={props.rootClasses}>
      <div className="logo">
        <img src={dcpUrl + "var/images/thumbnail/images/x/xzp/upload-1251824831-parat_logo.png"} />
      </div>
      <div className="dme-w-coverimage">
        <img
          src={dmeConfig.general.imagePath(props.blockNode.data.coverImage)}
        />
      </div>
      <div className="dme-w-list">
        <BlockListRender
          blockData={props.blockNode.children || []}
          path={props.path}
          mode={props.mode}
        />
      </div>
      <div className="footer">
        <img src={dcpUrl + "var/images/j/jum/upload-4067947600-screenshot_2024-04-29_at_15.08.18.png"} />
      </div>
    </LayoutContainer>
  );
};
