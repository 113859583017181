import { css } from "@emotion/css";
import { DME } from "dmeditor";

export const Line = (props: DME.WidgetRenderProps) => {
  return (
    <div
      className={css`
        padding: 10px 0px;
      `}
    >
      <hr
        className={css`
          border-top: 1px solid var(--parat-line-color);
        `}
      />
    </div>
  );
};
