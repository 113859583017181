import React, { useState } from "react";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import { addDays } from "date-fns";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css";
import { DateRangePicker, RangeKeyDict } from "react-date-range";
import { useDcpAuth } from "../../../../contexts/DcpAuthContext";
import { useTranslation } from "react-i18next";

import checkPermission from "./permissions";

const FilterComponent = ({
  setDate,
  type,
  setType,
  isTemplate,
  isDownloadPage,
  roles,
  selectedRole,
  setSelectedRole,
}: {
  setDate: Function;
  type?: string;
  setType?: Function;
  isTemplate: boolean;
  roles?: string[];
  selectedRole?: string;
  setSelectedRole?: Function;
  isDownloadPage: boolean;
}) => {
  const [dateDialogOpen, setDateDialogOpen] = useState(false);
  const [dateRange, setDateRange] = useState<{
    startDate: string;
    endDate: string;
  }>({ startDate: "", endDate: "" });
  const [dateState, setdateState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    },
  ]);
  const { role } = useDcpAuth();

  const { t } = useTranslation("FilterComponentPage");

  const handleDateDialogOpen = () => {
    setDateDialogOpen(true);
  };

  const handleDateClose = () => {
    setDateDialogOpen(false);
  };

  const handleDateSave = async () => {
    setDate({
      startDate: dateState[0].startDate.toLocaleDateString(),
      endDate: dateState[0].endDate.toLocaleDateString(),
    });
    setDateRange({
      startDate: dateState[0].startDate.toLocaleDateString(),
      endDate: dateState[0].endDate.toLocaleDateString(),
    });
    handleDateClose();
  };

  const handleSelect = (ranges: RangeKeyDict) => {
    if (ranges.selection.startDate && ranges.selection.endDate) {
      const dateObj: {
        startDate: Date;
        endDate: Date;
        key: string;
      }[] = [
        {
          startDate: new Date(ranges.selection.startDate.toLocaleDateString()),
          endDate: new Date(ranges.selection.endDate.toLocaleDateString()),
          key: "selection",
        },
      ];
      setdateState(dateObj);
    }
  };

  return (
    <div className=" pt-2 flex flex-row gap-4 items-center justify-center">
      {isTemplate && checkPermission(role, "filter", "filterType") && (
        <FormControl margin="normal" size="small">
          <InputLabel id="demo-simple-select-label">{t("Type")}</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            required
            value={type as string}
            label={t("Type")}
            onChange={(e) => setType!(e.target.value)}
            sx={{ width: 128 }}
          >
            <MenuItem value={""}>{t("None")}</MenuItem>
            <MenuItem value={"newsletter"}>{t("Newsletter")}</MenuItem>
            <MenuItem value={"poster"}>{t("Poster")}</MenuItem>
            {isDownloadPage &&
              checkPermission(role, "filter", "filterTypeTemplate") && (
                <MenuItem value={"template"}>{t("Template")}</MenuItem>
              )}
          </Select>
        </FormControl>
      )}
      {isDownloadPage && checkPermission(role, "filter", "filterType") && (
        <FormControl margin="normal" size="small">
          <InputLabel id="demo-simple-select-label">{t("Type")}</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            required
            value={type as string}
            label={t("Type")}
            onChange={(e) => setType!(e.target.value)}
            sx={{ width: 128 }}
          >
            <MenuItem value={""}>{t("None")}</MenuItem>
            <MenuItem value={"newsletter"}>{t("Newsletter")}</MenuItem>
            <MenuItem value={"poster"}>{t("Poster")}</MenuItem>
            {isDownloadPage &&
              checkPermission(role, "filter", "filterTypeTemplate") && (
                <MenuItem value={"template"}>{t("Template")}</MenuItem>
              )}
          </Select>
        </FormControl>
      )}
      {isTemplate && checkPermission(role, "filter", "filterByRole") && (
        <FormControl margin="normal" size="small">
          <InputLabel id="demo-simple-select-label">{t("Role")}</InputLabel>
          <Select
            required
            value={selectedRole as string}
            label={t("Role")}
            onChange={(e) => setSelectedRole!(e.target.value)}
            sx={{ width: 128 }}
          >
            <MenuItem value={""}>{t("None")}</MenuItem>
            {roles?.map((roleItem) => {
              return (
                <MenuItem key={roleItem} value={roleItem}>
                  {roleItem}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      )}
      {isDownloadPage &&
        type == "template" &&
        checkPermission(role, "download", "filterByRole") && (
          <FormControl margin="normal" size="small">
            <InputLabel id="demo-simple-select-label">{t("Role")}</InputLabel>
            <Select
              required
              value={selectedRole as string}
              label={t("Role")}
              onChange={(e) => setSelectedRole!(e.target.value)}
              sx={{ width: 128 }}
            >
              <MenuItem value={""}>{t("None")}</MenuItem>
              {roles?.map((roleItem) => {
                return (
                  <MenuItem key={roleItem} value={roleItem}>
                    {roleItem}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        )}

      <Dialog open={dateDialogOpen} onClose={handleDateClose} maxWidth={false}>
        <DialogTitle>{t("Pick a date range")}</DialogTitle>
        <DialogContent className=" w-full">
          <DateRangePicker
            onChange={(item) => handleSelect(item)}
            showPreview={true}
            moveRangeOnFirstSelection={false}
            months={2}
            ranges={dateState}
            direction="horizontal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDateClose}>{t("Cancel")}</Button>
          <Button onClick={handleDateSave}>{t("Save")}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default FilterComponent;
