import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Paper';

export const ItemCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#10151a' : '#eaeaea',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
  overflow: 'auto',
}));