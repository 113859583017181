import React, { useState, useRef } from 'react';
import { useApi } from '../../../services/HttpService';
import { useParams } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';


export const WorkingCondition = () => {
  const { uid } = useParams(); // send with request to authenticate user
  const { api: employmentApi, response: employmentResponse, isLoading: employmentIsLoading, error: employmentError } = useApi();
  //const { api: positionCodesApi, response: positionCodesResponse, isLoading: positionCodesIsLoading, error: positionCodesError } = useApi();
  const { api: saveEmploymentApi, response: saveEmploymentResponse, isLoading: saveEmploymentIsLoading, error: saveEmploymentError } = useApi();
  const [isValidLastName, setLastNameIsValid] = useState(true);
  const [responseMessage, setResponseMessage] = useState("");
  const [isResponseReceived, setIsResponseReceived] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const initialValue = 0;
  

  const [personalInfo, setPersonalInfo] = useState({
    member_number: '',
    member_status: '',
    employer_name: '',
    employer_actor_id: '',
    adress2: '',
    adress3: '',
    postal_code: '',
    post_area: '',
    position_type: '',
    position_percent: '',
    salary_per_year: '',
    fritekst: '',
    valid: false,
  });

  // const [nameError, setNameError] = useState("");
  // const [emailError, setEmailError] = useState(false);

  React.useEffect(() => {

    const getemployment = async () => {
      await employmentApi("/getEmploymentData", "POST", { uid : uid })
      //await positionCodesApi("/getpositionCodes", "POST")
    }

    getemployment();
  },[]);

  React.useEffect(() => {
  if(employmentResponse && employmentResponse.data != null){
    setPersonalInfo({...personalInfo, 
      member_number: employmentResponse.data.member_number,
      employer_name: employmentResponse.data.employer_name,
      employer_actor_id: employmentResponse.data.employer_actor_id,
      adress2: employmentResponse.data.adress2,
      adress3: employmentResponse.data.adress3,
      postal_code: employmentResponse.data.postal_code,
      post_area: employmentResponse.data.city,
      position_type: employmentResponse.data.position_type,
      position_percent: employmentResponse.data.position_percent,
      salary_per_year: employmentResponse.data.salary_per_year,
      fritekst: employmentResponse.data.fritekst,
    });
  }
  }, [employmentResponse]);

  const resetInput = () => {
    setPersonalInfo({...personalInfo, 
      member_number: employmentResponse.data.member_number,
      employer_name: employmentResponse.data.employer_name,
      employer_actor_id: employmentResponse.data.employer_actor_id,
      adress2: employmentResponse.data.adress2,
      adress3: employmentResponse.data.adress3,
      postal_code: employmentResponse.data.postal_code,
      post_area: employmentResponse.data.city,
      position_type: employmentResponse.data.position_type,
      position_percent: employmentResponse.data.position_percent,
      salary_per_year: employmentResponse.data.salary_per_year,
      fritekst: employmentResponse.data.fritekst,
    });
  }

  const toggleEditMode = () => {
    if(isEditing){
      resetInput();
    }
    setIsEditing(!isEditing);
  };

  const toggleReply = (reply: string) => {
    setResponseMessage(reply)
    setIsResponseReceived(true)
  };

  const SaveEmployment = async () => {
    await saveEmploymentApi("/SaveEmploymentData", "POST", { 
      member_number: personalInfo.member_number,
      member_status: personalInfo.member_status,
      employer_name: personalInfo.employer_name,
      employer_actor_id: personalInfo.employer_actor_id,
      position_type: personalInfo.position_type,
      position_percent: personalInfo.position_percent,
      salary_per_year: personalInfo.salary_per_year,
      fritekst: personalInfo.fritekst,
    })
  }

  React.useEffect(() => {
    if (saveEmploymentResponse) {
      toggleReply(saveEmploymentResponse.data.response)
    }
  }, [saveEmploymentResponse])

  const validateEmail = (email: string) => {
    if (email == "")
    return true

    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const ValidateAll = () => {
    
    var validated = true


    return validated
  }

  const saveChanges = () => {

    if (ValidateAll()){
      SaveEmployment();
    }
    setIsEditing(false)
    console.log('ResponseMessage: ', responseMessage);
    console.log('Saving changes:', personalInfo);
  };

  const nameValidator = (value:string) => {
    if (value == ""){
      return true
    }
    // if (value.length < 2) return "Name must be at least 2 characters long";
    // if (value.length > 25) return "Name must be less than 25 characters long";
    if (!/^[a-zA-Z ]+$/.test(value)){
      return false;
    }
    return true;
  };
  
  const formValid = useRef({ name: false, email: false });

  const handleSubmit = (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    if (Object.values(formValid.current).every(isValid => isValid)) {
      alert("Form is valid! Submitting the form...");
    } else {
      alert("Form is invalid! Please check the fields...");
    }
  };

  return (
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1},
        width: 800,
        maxWidth: '100%',
        overflow: "hidden",
        textOverflow: "ellipsis",
        "& .MuiInputBase-input.Mui-disabled": {
          WebkitTextFillColor: "#000000",
      },
      }}
      noValidate
      autoComplete="off"
      
    >
      <Typography variant="h6">Arbeidsforhold</Typography>
      <FormControl sx={{ width: '50ch' }}>
      <InputLabel id="input-label-memberstatus">Medlemsstatus</InputLabel>
      <Select
        labelId="select-label-memberstatus"
        id="simple-select-memberstatus"
        value={personalInfo.member_status}
        label="Medlemsstatus"
        onChange={(e) => setPersonalInfo({...personalInfo, member_status: e.target.value })}
        disabled={!isEditing}
      >
        <MenuItem value={'Yrkesaktiv'}>Yrkesaktiv</MenuItem>
        <MenuItem value={'Arbeidsledig'}>Arbeidsledig. Du blir registrert som passivt medlem.</MenuItem>
        <MenuItem value={'Permittert'}>Permittert. Du er fritatt for kontingent.</MenuItem>
        <MenuItem value={'Pensjonist'}>Pensjonist. Du blir registrert som passivt medlem.</MenuItem>
        <MenuItem value={'Arbeidsavklaringspenger'}>Arbeidsavklaringspenger </MenuItem>
        <MenuItem value={'Annet'}>Annet. Du blir registrert som passivt medlem</MenuItem>
        <MenuItem value={'Sykmeldt'}>Sykmeldt/Foreldrepermisjon. Utbetalt fra NAV.</MenuItem>
        <MenuItem value={'permisjonUlønnet'}>Permisjon uten lønn</MenuItem>
        <MenuItem value={'Lærling'}>Lærling. Du betaler ikke kontingent</MenuItem>
        <MenuItem value={'Student'}>Student/elev. Du betaler ikke kontingent</MenuItem>
      </Select>
      </FormControl>
      <div>
      <TextField
        sx={{ width: '25ch' }}
        label="Arbeidssted"
        value={personalInfo.employer_name}
        disabled={!isEditing}
        variant="standard"
      />
      </div>
      <div>
      <TextField
        sx={{ width: '35ch' }}
        label="Adresse 1"
        value={personalInfo.adress2}
        onChange={(e) => setPersonalInfo({...personalInfo, adress2: e.target.value })}
        disabled={true}
        variant="standard"
      />
      </div>
      <div>
      <TextField
        sx={{ width: '35ch' }}
        label="Adresse 2"
        value={personalInfo.adress3}
        onChange={(e) => setPersonalInfo({...personalInfo, adress3: e.target.value })}
        disabled={true}
        variant="standard"
      />
      </div>
      <div>
      <TextField
        sx={{ width: '10ch' }}
        label="Postnr"
        value={personalInfo.postal_code}
        onChange={(e) => setPersonalInfo({...personalInfo, postal_code: e.target.value })}
        disabled={true}
        variant="standard"
        type="number"
      />
      </div>
      <div>
      <TextField
      sx={{ width: '13ch' }}
        label="Poststed"
        value={personalInfo.post_area}
        onChange={(e) => setPersonalInfo({...personalInfo, post_area: e.target.value })}
        disabled={true}
        variant="standard"
      />
      </div>
      <div>
      <FormControl sx={{ width: '50ch' }}>
        <InputLabel id="input-select-label">Stilling</InputLabel>
        <Select
          labelId="select-label"
          id="select-position"
          value={personalInfo.position_type}
          label="Stilling"
          onChange={(e) => setPersonalInfo({...personalInfo, position_type: e.target.value })}
          disabled={!isEditing}
        >
          {employmentResponse && employmentResponse.data.position_codes?.map((item: { code: number; description: string }, index: number) => (
            <MenuItem key={index} value={item.code}>
              {item.description}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      </div>
      <div>
      <TextField
        label="Stillingsprosent"
        value={personalInfo.position_percent}
        onChange={(e) => setPersonalInfo({...personalInfo, position_percent: e.target.value })}
        disabled={!isEditing}
        variant="standard"
        type="number"
      />
      </div>
      <div>
      <TextField
        label="Årslønn (100%)"
        value={personalInfo.salary_per_year}
        onChange={(e) => setPersonalInfo({...personalInfo, salary_per_year: e.target.value })}
        disabled={!isEditing}
        variant="standard"
        type="number"
      />
      </div>
      <div>
      <TextField
        sx={{ width: '35ch' }}
        label="Fritekst"
        value={personalInfo.fritekst}
        onChange={(e) => setPersonalInfo({...personalInfo, fritekst: e.target.value })}
        disabled={!isEditing}
        variant="standard"
      />
      </div>
      <div>
      <Button 
      onClick={toggleEditMode}
      color="primary"
      variant="contained"
      size="large"
      sx={{ mt: 2 }}
      >
        {isEditing? 'Avbryt' : 'Endre'}
      </Button>
      {isEditing && (
        <Button 
        onClick={saveChanges}
        color="primary"
        variant="contained"
        size="large"
        sx={{ mt: 2, ml: 2 }}
        >
            Lagre
        </Button>
      )}
      </div>
      <div style={{margin:'15px 0 0 0'}}>
      {isResponseReceived && (
        <TextField
          multiline maxRows={3}
          minRows={2}
          variant='filled'
          value={responseMessage}
          InputProps={{
            readOnly: true, // Make the TextField read-only
          }}
          sx={{ width:'50ch'}} // Optional: Add margin-top for spacing
        />
      )}
      </div>
    </Box>
  );
  {responseMessage && <p>{responseMessage}</p>}
};