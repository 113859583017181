import styled from "@emotion/styled";

export const LayoutContainer = styled.div`
  --layout-height: 1200px;

  background: var(--parat-orange);
  color: white;
  height: calc(var(--dme-container-total-width)* 1.41414);
  overflow-y: auto;

  & > .dme-w-list {
    padding: 20px;
    max-height: 600px;
    overflow-y: auto;
  }
  .dme-w-coverimage img {
    width: 100%;
  }

  .logo {
    position: absolute;
    top: 20px;
    right: 20px;
    img {
      width: 80px;
    }
  }

  .footer {
    padding: 0px 20px 20px 20px;
    position: absolute;
    bottom: 0;
    img {
      height: 40px;
    }
  }
`;
