import React, { useEffect, useState, useRef } from "react";
import { DMEData, DMEditor, DMEditorView } from "dmeditor";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { sendDataToBackend } from "./lib/ConvertImage";
import { useDcpAuth } from "../../../contexts/DcpAuthContext";
import { useDcpApi, availablePaths } from "../../../services/DcpService";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { css } from "@emotion/css";
import { DMEditorRefType } from "dmeditor/core/main/designer/DMEditor";
import { Box, CircularProgress } from "@mui/material";
import "./styles/browse-image.css";
import { Saving } from "./components/Saving";
import { DMEditorWithPreview } from "./DMEditorWithPreview";

const CreateTemplate: React.FC = () => {
  const { title, pageType } = useParams();

  const navigate = useNavigate();
  const { accessToken } = useDcpAuth();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const { dcpApi, dcpResponse, dcpIsLoading, dcpError } = useDcpApi();

  const { t } = useTranslation("EditPage");

  const saveContent = async (data: any) => {
    setIsSaving(true);

    try {
      const fileName: string = "MyPoster.png";
      const payloadKey: string = "file";
      let previewUrl = "";
      const reqMethod = "POST";
      const reqURL = availablePaths.imageUpload.uploadImageFile();
      console.log("sendDataToBackend");
      const responseData = await sendDataToBackend(
        "dmeditor-view",
        fileName,
        payloadKey,
        reqURL,
        reqMethod
      );
      if (!responseData.error) previewUrl = responseData.data;

      const payload = {
        body: data.data,
        title: data.page.title,
        type: pageType,
        preview: previewUrl,
      };

      const url = availablePaths.templateApi.createTemplate();

      await dcpApi(url, "POST", payload);
      toast.success("Saved successfully");
      setIsSaving(false);
      navigate(-1);
    } catch {
      toast.error(t("There was an error saving the content"));
      setIsSaving(false);
    }
  };

  useEffect(() => {
    if (dcpError) {
      console.error("Save failed:", dcpError);
      toast.error(t("There was an error saving the content"));
      setIsSaving(false);
    }
  }, [dcpError]);

  return (
    <div>
      {isSaving && <Saving />}
      <DMEditorWithPreview
        data={[]}
        title={title}
        onSave={saveContent}
        onCancel={() => {
          navigate(-1);
        }}
      />
    </div>
  );
};

export default CreateTemplate;
