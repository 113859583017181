import { DME, DMEData } from "dmeditor";
import { nanoid } from "nanoid";
import { LayoutFixedPageEntity } from "./entity";
const definition: DME.Widget = {
  category: "basic",
  icon: "parat-logo",
  name: "Layout fixed page",
  type: "parat-layout-fixed-page",
  events: {
    createBlock: (): DMEData.Block<LayoutFixedPageEntity> => {
      return {
        id: nanoid(),
        type: "parat-layout-fixed-page",
        style: { _: "default" },
        data: {
          settings: { height: 800 },
          footer:
          process.env.REACT_APP_DCP_URL + "var/images/j/jbk/upload-386742331-newsletter_1.jpg",
        },
        children: [],
      };
    },
    updateData: () => {},
  },
  settings: [
    {
      name: "Content height",
      property: "settings.height",
      settingComponent: "input",
    },
  ],
};

export default definition;
