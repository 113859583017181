import {
  initLanguage,
  registerWidgetStyle,
  registerWidgetStyleOption,
  registerWidgetVariant,
  setDMEditorCallback,
  setDMEditorConfig,
} from "dmeditor";
import { nanoid } from "nanoid";
import registerNewsLetter from "./newsletter-header";
import registerLayoutFixedPage from "./layout-fixed-page";
import registerLayoutPosterLeftright from "./layout-poster-leftright";
import registerLayoutPoster from "./layout-poster";
import registerLine from "./line";
import { registerDefaultWidgets } from "dmeditor";
import { EditImage } from "./plugins/crop/EditImage";
import { BrowseImage } from "./callbacks/BrowseImage";

export const dmeditorInit = () => {
  initLanguage("nor-NO");
  registerDefaultWidgets();
  registerNewsLetter();
  registerLayoutFixedPage();
  registerLayoutPosterLeftright();
  registerLayoutPoster();
  registerLine();

  registerWidgetVariant({
    identifier: "parat-newsletter-header-text",
    name: "Newsletter header text",
    category: "basic",
    widget: "text",
    getDefaultData: () => {
      return {
        id: nanoid(),
        type: "text:parat-newsletter-header-text",
        data: {
          value: [
            {
              type: "parargraph",
              children: [{ text: "Medlemsinfo uke 33 - Mandag 27.juni 2023" }],
            },
          ],
        },
        style: { _: "parat-newsletter-header-text" },
      };
    },
  });

  registerWidgetVariant({
    identifier: "article-section",
    name: "Article section",
    widget: "list",
    category: "basic",
    getDefaultData: () => {
      return {
        id: nanoid(),
        type: "list:article-section",
        data: {},
        style: { _: "default" },
        children: [
          {
            id: nanoid(),
            type: "heading",
            data: { level: 2, value: "Heading title" },
            style: {},
          },
          {
            id: nanoid(),
            type: "text",
            data: {
              value: [
                {
                  type: "parargraph",
                  children: [{ text: "Ingress" }],
                },
              ],
            },
            style: { _: "summary" },
          },
        ],
      };
    },
  });

  registerWidgetStyleOption("list:article-section", [
    {
      identifier: "default",
      name: "Default",
      cssStyle: `
        padding-bottom: 20px;
  `,
    },
  ]);

  registerWidgetStyleOption("text", [
    {
      identifier: "summary",
      name: "Ingress",
      cssStyle: `
        padding: 10px 0px;
        font-weight: bold;
  `,
    },
  ]);

  registerWidgetStyleOption("text:parat-newsletter-header-text", [
    {
      identifier: "parat-newsletter-header-text",
      name: "Parat newsletter style",
      cssStyle: `
      border-top: 1px solid var(--parat-line-color);
      border-bottom: 1px solid var(--parat-line-color);    
      font-size: 16px;  
      padding: 5px 0px;
      &::after{
        content: 'www.parat.com';
        position: absolute;
        bottom: 0px;
        right: 5px;
        top: 5px;
      }
  `,
    },
  ]);

  registerWidgetStyleOption("layout-2columns", [
    {
      identifier: "default",
      name: "Newsletter main",
      cssStyle: `
      margin-top: 20px;

      border-bottom: 1px solid var(--parat-line-color);
      padding-bottom: 10px;

      .dme-w-column1{
        border-right: 1px solid var(--parat-line-color);
        padding-right: 10px;
      }

      .dme-w-column2{
        padding-left: 10px;
      }
  `,
    },
  ]);

  registerWidgetStyle("text", {
    identifier: "line-height",
    display: "dropdown",
    name: "Line height",
    options: [
      {
        identifier: "list-1-5",
        name: "List",
        cssStyle: `
        ul > li{
          margin-top: 15px;
        }
      `,
      },
    ],
  });

  registerWidgetStyle("text", {
    identifier: "background",
    display: "dropdown",
    name: "Background",
    options: [
      {
        identifier: "gray",
        name: "Gray",
        cssStyle: `
          background: #ECEAE6;
      `,
      },
    ],
  });

  registerWidgetStyle("text", {
    identifier: "size",
    display: "dropdown",
    name: "Size",
    options: [
      {
        identifier: "size18",
        name: "18",
        cssStyle: `
        font-size: 18px;
      `,
      },
    ],
  });

  registerWidgetStyleOption("heading", [
    {
      identifier: "parat-header-small",
      name: "Parat header orange",
      cssStyle: `      
      h1, h2, h3{
        color: var(--parat-orange)
      }
  `,
    },
  ]);

  setDMEditorConfig({
    general: {
      projectStyles: {
        default: `
        --parat-orange: rgb(233, 81, 15);
        --parat-line-color:#666666;

        --dme-container-width: 1000px !important;
        --dme-container-total-width: 1000px;
        max-width:100% !important;
        min-width: var(--dme-container-total-width);
        font-family: Arial !important;

        [contenteditable]:focus {
          outline: 0px solid transparent;
        }

        h1{
            font-size: 2rem;
        }

        h2{
            font-size: 1.5rem;
        }

        h3{
          font-size: 1.25rem;
        }

        a{
            color: var(--parat-orange);
            text-decoration: underline;
        }

        img{
          display:inline-block;
        }

        ul{
          padding: 0px 20px;
        }

        .dme-w-image+.dme-w-description{
          font-size: 14px;
          margin-top: 5px;
        }
        `,
      },
      imagePath: (path) => {
        if (!path.startsWith("http://") && !path.startsWith("https://")) {
          return process.env.REACT_APP_ASSET_URL?.replace("{imagepath}", path);
        }
        return path;
      },
    },
    editor: {
      favouriteWidgets: ["heading", "text", "image", "list:article-section"],
      defaultTheme: "default",
      ui: {},
      colors: {
        text: [{color: "#ffffff"}, {color: "#000000"}, {color: "#333333"}, {color: "#E9510F"}],
        background: [{color: "#000000"}, {color: "#ffffff"}, {color: "#cccccc"}, {color: "#E9510F"}],
      },
      enableEditControl: true,
    },
    plugins: {
      imageHandlers: [EditImage],
    },
  });

  setDMEditorCallback({ browseImage: BrowseImage });
};
