import { DME, DMEData } from "dmeditor";
import { nanoid } from "nanoid";
import { LayoutPosterLeftrightEntity } from "./entity";
const definition: DME.Widget = {
  category: "basic",
  icon: "parat-logo",
  name: "Layout poster left right",
  type: "parat-layout-poster-leftright",
  events: {
    createBlock: (): DMEData.Block<LayoutPosterLeftrightEntity> => {
      return {
        id: nanoid(),
        type: "parat-layout-poster-leftright",
        style: { _: "default" },
        data: {
          coverImage:
          process.env.REACT_APP_DCP_URL + "var/images/r/rnx/upload-4199591887-poster_1.jpg",
        },
        children: [],
      };
    },
    updateData: () => {},
  },
  settings: [
    {
      name: "Cover image",
      property: ".coverImage",
      settingComponent: "image",
    },
  ],
};

export default definition;
