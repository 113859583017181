import { DME, DMEData } from "dmeditor";
import { nanoid } from "nanoid";
import { LayoutPosterEntity } from "./entity";
const definition: DME.Widget = {
  category: "basic",
  icon: "parat-logo",
  name: "Layout poster",
  type: "parat-layout-poster",
  events: {
    createBlock: (): DMEData.Block<LayoutPosterEntity> => {
      return {
        id: nanoid(),
        type: "parat-layout-poster",
        style: { _: "default" },
        data: {
          coverImage:
            "https://images.hindustantimes.com/rf/image_size_960x540/HT/p2/2018/06/12/Pictures/_f70710be-6e2f-11e8-9a75-8898ac94ce9e.jpg",
        },
        children: [],
      };
    },
    updateData: () => {},
  },
  settings: [
    {
      name: "Cover image",
      property: ".coverImage",
      settingComponent: "image",
      parameters: {
        ratio: 10 / 4,
      },
    },
  ],
};

export default definition;
