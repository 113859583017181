import { Alert, Box, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useParams } from "react-router-dom";
import { useDcpAuth } from "../../../contexts/DcpAuthContext";
import { useDcpApi, availablePaths } from "../../../services/DcpService";
import { useApi } from "../../../services/HttpService";
import FilterComponent from "./components/FilterComponent";
import SearchFilterView from "./components/SearchFilterView";
import TemplateCard from "./components/TemplateCard";
import { RoleResponse, TemplateResponse } from "./interfaces";

export const PosterTemplate = () => {
  const { uid } = useParams(); // send with request to authenticate user
  const {
    initialLoad,
    authIsLoading,
    authError,
    isAuthenticated,
    authenticate,
  } = useDcpAuth();
  const { dcpApi, dcpResponse, dcpIsLoading, dcpError } = useDcpApi();
  const {
    dcpApi: dcpRoleApi,
    dcpResponse: dcpRoleResponse,
    dcpIsLoading: dcpRoleIsLoading,
    dcpError: dcpRoleError,
  } = useDcpApi();

  const [templates, setTemplates] = useState<TemplateResponse[]>([]);
  const [mutableTemplates, setMutableTemplates] = useState<TemplateResponse[]>(
    []
  );
  const [searchString, setSearchString] = useState<string>("");
  const [date, setDate] = useState<{ startDate: string; endDate: string }>({
    startDate: "",
    endDate: "",
  });
  const [currentTemplate, setCurrentTemplate] = useState<TemplateResponse[]>(
    []
  );
  const [itemOffset, setItemOffset] = useState<number>(0);
  const [pageCount, setPageCount] = useState<number>(0);
  const [activePage, setActivePage] = useState<number>(0);
  const [type, setType] = useState<string>("");
  const [roles, setRoles] = useState<string[]>([]);
  const [roleObject, setRolebjects] = useState<RoleResponse[]>([]);
  const [selectedRole, setSelectedRole] = useState<string>("");
  const itemsPerPage = 6;
  const [layout, setLayout] = useState<string>("card");

  useEffect(() => {
    if (initialLoad && uid) {
      authenticate(uid, "");
    }
  }, [initialLoad]);

  useEffect(() => {
    const fetchTemplates = async () => {
      await dcpApi(availablePaths.templateApi.getTemplateList(), "GET");
    };

    const fetchRoles = async () => {
      await dcpRoleApi(availablePaths.roles.getRoleList(), "GET");
    };

    if (isAuthenticated) {
      fetchTemplates();
      fetchRoles();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (dcpResponse?.data?.list) {
      setTemplates(dcpResponse.data.list);
      setMutableTemplates(dcpResponse.data.list);
    }
  }, [dcpResponse]);

  useEffect(() => {
    if (dcpRoleResponse) {
      if (!dcpRoleResponse.error && dcpRoleResponse.data.list) {
        let tempRole: string[] = [];
        let tempRoleObj: RoleResponse[] = [];
        dcpRoleResponse.data.list.map((role: RoleResponse) => {
          if (role.identifier === "template") {
            tempRole.push(role.name);
            tempRoleObj.push(role);
          }
        });
        setRoles(tempRole);
        setRolebjects(tempRoleObj);
      }
    }
  }, [dcpRoleResponse]);

  useEffect(() => {
    let filteredTemplates = templates.filter((obj) =>
      obj.title.toLowerCase().includes(searchString.toLowerCase())
    );

    if (type.length > 0) {
      filteredTemplates = filteredTemplates.filter(
        (obj) => obj.type.value === type
      );
    }
    if (selectedRole !== "") {
      const selectedRoleObject = roleObject.find(
        (role) => role.name === selectedRole
      );
      if (selectedRoleObject && selectedRoleObject.templates) {
        const roleTemplates = [...selectedRoleObject.templates];
        let filteredArray: TemplateResponse[] = [];
        roleTemplates.map((id) => {
          let t = filteredTemplates.find((templ) => templ.location.id === id);
          if (t) {
            filteredArray.push(t);
          }
        });
        filteredTemplates = filteredArray;
      }
    }

    setItemOffset(0);
    setActivePage(0);

    setMutableTemplates(filteredTemplates);
  }, [searchString, type, selectedRole]);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    const currentItems = mutableTemplates.slice(itemOffset, endOffset);
    const tempPageCount = Math.ceil(mutableTemplates.length / itemsPerPage);
    setCurrentTemplate(currentItems);
    setPageCount(tempPageCount);
  }, [mutableTemplates, itemOffset]);

  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % mutableTemplates.length;
    setItemOffset(newOffset);
    setActivePage(event.selected);
  };

  return (
    <Box sx={{ maxWidth: "1050px", margin: "0 auto" }}>
      {authIsLoading ? (
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <CircularProgress color="info" />
        </Box>
      ) : authError ? (
        <Alert severity="error">{authError}</Alert>
      ) : !isAuthenticated ? (
        <Alert severity="error">Missing permission</Alert>
      ) : (
        <div className="w-full">
          <div className="w-full flex flex-row items-start justify-between">
            <FilterComponent
              setDate={setDate}
              isTemplate={true}
              type={type}
              setType={setType}
              roles={roles}
              selectedRole={selectedRole}
              setSelectedRole={setSelectedRole}
              isDownloadPage={false}
            />
            <SearchFilterView
              setSerachString={setSearchString}
              setLayout={setLayout}
              // date={date}
              // setDate={setDate}
            />
          </div>
          {dcpIsLoading ? (
            <Box
              sx={{ width: "100%", display: "flex", justifyContent: "center" }}
            >
              <CircularProgress color="info" />
            </Box>
          ) : dcpError ? (
            <Alert severity="error">{dcpError}</Alert>
          ) : dcpResponse ? (
            <div
              className={
                layout === "list"
                  ? "grid grid-cols-1 mb-5 gap-4"
                  : "grid-container"
              }
            >
              {currentTemplate.map((template) => (
                <TemplateCard
                  key={template.id}
                  template={template}
                  currentTemplate={mutableTemplates}
                  setCurrentTemplate={setMutableTemplates}
                  layout={layout}
                />
              ))}
            </div>
          ) : null}
          <div className="w-full flex flex-row items-center justify-center mx-auto">
            <ReactPaginate
              breakLabel="..."
              nextLabel=" >"
              onPageChange={handlePageClick}
              pageRangeDisplayed={4}
              marginPagesDisplayed={2}
              pageCount={pageCount}
              previousLabel="< "
              renderOnZeroPageCount={null}
              forcePage={activePage}
              breakClassName="bg-gray-200 rounded-full mx-1 py-1 pb-2 px-2"
              breakLinkClassName="text-gray-600"
              containerClassName="flex"
              pageClassName="mx-1"
              pageLinkClassName="text-black font-semibold border border-black rounded-full px-3 py-2 "
              activeLinkClassName="text-black bg-[#F97316]"
              previousClassName="mx-1"
              previousLinkClassName="text-black font-semibold bg-white border border-black rounded-full px-3 py-2 "
              nextClassName="mx-1"
              nextLinkClassName="text-black font-semibold bg-white border border-black rounded-full px-3 py-2 "
            />
          </div>
        </div>
      )}
    </Box>
  );
};
