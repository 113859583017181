import { PDFDocument } from "pdf-lib";
import { availablePaths } from "../../../../services/DcpService";
import toast from "react-hot-toast";

const convertPdfUrl = process.env.REACT_APP_PDF_CONVERTER_URL;

/**
 * Sends dmeditor body to external service that creates html and converts it to html then pdf
 * @param body
 */
export const convertHtmlToPdf = async (body: any, title: string) => {
  console.log("body:", body);
  if (!convertPdfUrl) {
    throw new Error("pdf converter url not set in env");
  }

  const dmdata = body ?? [];

  try {
    const response = await fetch(convertPdfUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: dmdata }),
    });

    console.log("response", response);
    if (!response.ok) {
      throw new Error("response was not ok");
    }

    // get pdf blob
    const pdfBlob = await response.blob();
    const pdfUrl = URL.createObjectURL(pdfBlob);

    // open in new tab
    // const manipulatedUrl = `${pdfUrl}#${encodeURIComponent(title)}`;
    // window.open(manipulatedUrl, '_blank');
    // return;

    // download
    const a = document.createElement("a");
    a.href = pdfUrl;
    a.download = title;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(pdfUrl);
  } catch (err) {
    console.log("error when converting to pdf", err);
    toast.error("There was an error creating pdf");
  }
};
