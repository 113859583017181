import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

import getCroppedImg from "./CropImage";
import ImageCropDialog from "./ImageCropDialog";
import { dmeConfig } from "dmeditor";
import { useDcpAuth } from "../../../../../../contexts/DcpAuthContext";
import { multipartApi, useDcpApi } from "../../../../../../services/DcpService";

const dcpUrl = process.env.REACT_APP_DCP_URL;

export const EditImage = (props) => {
  const [open, setOpen] = useState(false);
  const [rotate, setRotate] = useState(0);
  const { accessToken } = useDcpAuth();
  const { dcpApi, dcpResponse, dcpIsLoading, dcpError } = useDcpApi();

  const [croppedAreaPixels, setCroppedAreaPixels] = useState<{
    width: number;
    height: number;
    x: number;
    y: number;
  }>(null);
  const [croppedImageUrl, setCroppedImageUrl] = useState(null);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const saveNewImage = async (path: string, name: string): Promise<string> => {
    const response = await dcpApi(dcpUrl + "api/content/create/image/10", "POST", { name: name, image: path });

    if (response) {
      return response.data.image;
    }
    return "";
  };

  const replaceImage = async (path: string, existing: string) => {
    console.log("exisitng", existing);
    const data = await dcpApi(dcpUrl + "api/content/list/image?field.image=" + existing, "GET")

    if (data?.data?.count === 1) {
      const id = data.data.list[0].id;
      const respData = await dcpApi(dcpUrl + "api/content/update/image/" + id, "POST",{ image: path });
      
      if (respData?.data && respData.error === false) {
        const updatedData = await dcpApi(dcpUrl + "api/content/get/image/" + id, "GET");
        if (updatedData) {
          return updatedData.data.image;
        }
      }
    }

    return "";
  };

  const saveImage = async (mode: "replace" | "new") => {
    const imageBlob = await getCroppedImg(
      dmeConfig.general.imagePath(props.image.src),
      croppedAreaPixels,
      rotate
    );

    const formData = new FormData();
    if (!imageBlob) {
      return;
    }
    formData.append("file", imageBlob, "image.jpg");

    const uploadFile = await multipartApi(dcpUrl + "api/util/uploadfile?service=content", formData);
    
    let newPath = "";

    if (uploadFile) {
      const uploadFileName = uploadFile.data;
  
      if (mode === "new") {
        newPath = await saveNewImage(uploadFileName, "Image " + new Date().toLocaleString());
      } else if (mode === "replace") {
        newPath = await replaceImage(uploadFileName, props.image.src);
      } else {
        return;
      }
    }

    if (newPath) {
      //update data
      props.onChange({
        ...props.image,
        src: dmeConfig.general.imagePath(newPath),
      });
      setOpen(false);
    }
  };

  return (
    <>
      <Button color="info" onClick={handleOpen}>
        Edit
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            width: "70%",
          },
        }}
      >
        <DialogTitle>Edit Image</DialogTitle>

        <ImageCropDialog
          id={props.image.id}
          imageUrl={dmeConfig.general.imagePath(props.image.src)}
          aspectInit={{
            value: props?.parameters?.ratio || 1,
            text: "Pre defined",
          }}
          setCroppedAreaPixels={setCroppedAreaPixels}
          setRotate={setRotate}
          rotate={rotate}
        />

        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={() => saveImage("replace")}>Replace existing</Button>
          <Button onClick={() => saveImage("new")}>Save a new</Button>
        </DialogActions>
      </Dialog>{" "}
    </>
  );
};
