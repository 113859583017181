import * as React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { themeStore } from '../themes/BaseTheme';
import { PaletteMode, useTheme } from '@mui/material';

export const DarkModeSwitch = () => {
  const theme = useTheme();
  let dark: PaletteMode = "dark";
  const [darkMode, setDarkMode] = React.useState(false);
  
  React.useEffect(() => {
    setDarkMode(theme.palette.mode === "dark");
  }, [theme.palette.mode])

  const handleDarkModeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDarkMode(event.target.checked);
    themeStore.getState().setMode(darkMode ? "light" : "dark")
  }

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Switch
            checked={darkMode}
            onChange={(e) => handleDarkModeChange(e)}
          />}
        label="Dark mode"
      />
    </FormGroup>
  )
}