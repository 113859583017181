import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import Image from 'mui-image';
import Logo from '../../../images/parat-logo.svg';
import { useNavigate } from "react-router-dom";
import { AuthService } from '../../../services/AuthService';
import { DarkModeSwitch } from '../../ui/DarkModeSwitch';
import { authStore } from '../../../App'

interface PageRoute {
  name: string;
  route: string;
  roles?: string[];
}

// const pages = ['Organisasjon', 'Medlem', 'Bedrift', 'Tillitsverv', 'Stipend', 'Tariff', 'Økonomi', 'Kommunikasjon', 'Admin'];
const pages: PageRoute[] = [
  { name: 'Organisasjon', route: '/organisasjon' },
  { name: 'Medlem', route: '/medlem' },
  { name: 'Bedrift', route: '/bedrift' },
  { name: 'Tillitsverv', route: '/tillitsverv' },
  { name: 'Stipend', route: '/stipend' },
  { name: 'Tariff', route: '/tariff' },
  { name: 'Økonomi', route: '/økonomi' },
  { name: 'Kommunikasjon', route: '/kommunikasjon' },
  { name: 'Admin', route: '/admin/table', roles: ['admin'] },
];

const settings = ['Profile', 'Settings', 'Account', 'Dashboard', 'Logout'];

function Header() {
  const { userRoles } = authStore();
  const { logout } = AuthService();
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const theme = useTheme();
  const organizationName = "PARAT";

  const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    marginRight: '5px',
    // width: 'auto',
    // [theme.breakpoints.up('sm')]: {
    ml: theme.spacing(1),
    width: 'auto',
    // },
  }));

  const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }));

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      // width: '100%',
      // [theme.breakpoints.up('sm')]: {
      width: '0ch',
      '&:focus': {
        width: '20ch',
      },
      // },
    },
  }));

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleSettingNavigation = (page: string) => {
    if (page === 'Logout') {
      // @todo: handle logout
      logout();
      navigate('/login');
    } else {
      handleCloseUserMenu();
      navigate('/' + page);
    }
  }

  const showPageRoute = (roles: string[] = []): boolean => {
    if (roles.length === 0) {
      return true;
    }

    let hasAccess = false;

    roles.forEach(role => {
      userRoles.forEach(userRole => {
        if (role === userRole) {
          hasAccess = true;
          return;
        }
      })
    })

    return hasAccess;
  }

  return (
    <AppBar position="static" sx={{ backgroundColor: theme.palette.primary.main, backgroundImage: 'none' }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {/* style={{ marginRight: "5px", display: Boolean(anchorElUser) ? 'block' : 'none' }} */}
          <Box sx={{ display: { xs: 'none', md: 'flex' }, cursor: 'pointer' }} onClick={() => navigate('/home')}>
            <Box sx={{ mr: 1 }}>
              <Image src={Logo} width="26px" height="34px" duration={0} shiftDuration={0} />
            </Box>
            <Typography
              variant="h6"
              noWrap
              // component="a"
              sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              {organizationName}
            </Typography>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page, index) => {
                if (showPageRoute(page.roles)) {
                  return (
                    <MenuItem key={index} onClick={() => navigate(page.route)}>
                      <Typography textAlign="center">{page.name}</Typography>
                    </MenuItem>
                  )
                }
              })}
            </Menu>
          </Box>
          <Box sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }}>
            <Image src={Logo} width="26px" height="34px" duration={0} shiftDuration={0} />
          </Box>
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="#app-bar-with-responsive-menu"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            {organizationName}
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page, index) => {
              if (showPageRoute(page.roles)) {
                return (
                  <Button
                    key={index}
                    onClick={() => navigate(page.route)}
                    sx={{ my: 2, color: 'white', display: 'block' }}
                  >
                    {page.name}
                  </Button>
                )
              }
            })}
          </Box>
          <Box>
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search…"
                inputProps={{ 'aria-label': 'search' }}
              />
            </Search>
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                {/* @todo replace Remy Sharp with user name, add profile pic eks: src="/static/public/avatar/2.png" */}
                <Avatar alt="Remy Sharp" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px', minHeight: '200px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem key='darkMode'>
                <DarkModeSwitch />
              </MenuItem>
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={() => handleSettingNavigation(setting)}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default Header;